import { Controller, FieldValues, UseControllerProps } from 'react-hook-form';
import React from 'react';
import { DateField, Month } from '@naf/input';

export const BirthDateMonth = <T extends FieldValues>({
  name,
  control,
  defaultValue,
  errorMessage,
  isDisabled,
  clearError,
  member,
  rules,
}: UseControllerProps<T> & {
  errorMessage?: string;
  isDisabled?: boolean;
  clearError?: () => void;
  member?: string;
}) => (
  <Controller
    name={name}
    control={control}
    defaultValue={defaultValue}
    rules={rules}
    render={({ field: { value, onChange } }) => (
      <DateField>
        <Month
          error={!!errorMessage}
          onChange={(val: any) => {
            onChange(val);
            if (clearError) clearError();
          }}
          value={value}
          autoComplete="bday-day"
          disabled={isDisabled}
          id={`${member || ''}birthmonth`}
          nextId={`${member || ''}birthyear`}
        />
      </DateField>
    )}
  />
);
